import { ShimState } from 'state-domains/index';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from 'state-domains/utils';
import { LOAD_STATUS_STALE } from 'state-domains/constants';
import { createSelector } from 'reselect';

import {
    ChartDataState,
    ChartOptions,
    ChartOptionState,
    ReportingState,
    ConvertedZScoreData,
} from '../types';

const reportingState = (state: Partial<ShimState>): ReportingState => {
    const { reporting: jsReporting = {} as ReportingState } = state || {};
    return jsReporting;
};

const chartOptionsState = createSelector(
    reportingState,
    ({
        chartOptions = {
            items: {},
            status: LOAD_STATUS_STALE,
            error: null,
        },
    }: ReportingState): ChartOptionState => chartOptions,
);

const chartOptions = createSelector(
    chartOptionsState,
    ({ items = {} }: ChartOptionState): ChartOptions => items,
);

const chartDataState = createSelector(
    reportingState,
    ({
        chartData = {
            items: [],
            releventFilters: {},
            status: LOAD_STATUS_STALE,
            error: null,
        },
    }: ReportingState): ChartDataState => chartData,
);

const chartData = createSelector(
    chartDataState,
    ({ items = [] }: ChartDataState): ConvertedZScoreData[] => items,
);

const chartFilterData = createSelector(
    chartDataState,
    ({ releventFilters = {} }: ChartDataState): Record<string, string> => releventFilters,
);

export const selectors = {
    reportingState,
    chartOptionsState,
    chartOptions,
    chartDataState,
    chartData,
    chartFilterData,
    isChartOptionsStatePending: isPendingSelector(chartOptionsState),
    isChartOptionsStateComplete: isCompleteSelector(chartOptionsState),
    isChartOptionsStateFailed: isFailedSelector(chartOptionsState),
    isChartDataStatePending: isPendingSelector(chartDataState),
    isChartDataStateComplete: isCompleteSelector(chartDataState),
    isChartDataStateFailed: isFailedSelector(chartDataState),
};
